<template>
  <div v-if="!loading">
    <div class="w-m-header">
      <div class="flex">
        <p class="font-14 font-bold no-margin col-grow" style="color: #293e52">Menus do site</p>
      </div>
    </div>
    <div class="w-m-body e-input-modern flex">
      <div class="menu-site-container" style="overflow: hidden">
        <div class="flex w-full">
          <div class="menu-site-body" style="margin: auto !important;" >
            <div class="tbox">
              <div class="tbox-content">
                <menus-list icon="trash" :list="menus" @select="openPage"></menus-list>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <window-loading :errors="errors" :status="status" v-else />
</template>

<script>
  import WindowLoading from '../../../../../layout/window/Loading'
  import {list} from '../../../../../../domain/menu-paginas/services'
  import MenusList from '../include/ListMenu'
  import windowMenu from '../window/windowMenu'

  export default {
    name: 'MenusWindowModern',
    components: {MenusList, WindowLoading},
    props: ['props', 'id'],
    data () {
      return {
        menus: [],
        loading: !!this.id,
        errors: null,
        success: false,
        status: null,
        source: false
      }
    },
    mounted () {
      this.load()
    },
    computed: {
    },
    methods: {
      load () {
        list(1000, 1, '')
                .then(response => {
                  this.menus = response.data.result
                  console.log(this.menus)
                  this.loading = false
                })
                .catch(error => {
                  this.alertApiError(error)
                  this.loading = false
                })
      },
      windowMenu,
      openPage({id}) {
        this.windowMenu(id)
      }
    }
  }
</script>
